import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import _ from 'underscore'
import * as Sentry from '@sentry/browser'
import FacebookLogin from 'react-facebook-login'
import { CSSTransitionGroup } from 'react-transition-group'
import {
  ControlLabel,
  FormGroup,
  FormControl,
  Button,
  HelpBlock
} from 'react-bootstrap'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'
import useMergedState from '../../hooks/useMergedState'

// Components
import Loading from '../Loading'
import RegistroFB from '../RegistroFB'

// Utils
import API from '../../utils/api'
import utils, { detectDevice } from '../../utils/utils'
import gaUtils from '../GAWrapper'

// Assets
import pickwinLogo from '../../assets/images/pickwinBCO.png'
import botonApple from '../../assets/images/botonappstore2.png'
import botonAndroid from '../../assets/images/botonandroid2.jpg'

import './registroFormPanel.scss'

export const DescargarAppBanner = ({ label, path, deviceType, handleClick }) => {
  if ($(window).width() > 769) {
    return null
  }

  return (
    <CSSTransitionGroup
      transitionName='example'
      transitionAppear
      transitionEnter
      transitionLeave
      transitionAppearTimeout={800}
      transitionLeaveTimeout={800}
    >
      <div className='hidden-md hidden-lg  flex-container justify align column glowingNote'>
        <div className=' register flex-col'>
          <div className='flex-container justify align'>
            <strong>Descarga la app de Pickwin </strong>
          </div>
          <div className='flex-container justify align column'>
            <div className='flex-col text-center '>¡ y ten la mejor experiencia !</div>
          </div>
          <div className='flex-row row-no-padding row-center'>
            <a
              className='flex-col col-40 col-offset-5'
              target='blank'
              href='https://itunes.apple.com/mx/app/pickwin/id1424544090'
            >
              <img
                onClick={() => {
                  handleClick = handleClick ? handleClick() : null
                }}
                src={botonApple}
                className='img-responsive '
              />
            </a>
            <a
              className='flex-col col-40 col-offset-5'
              target='blank'
              href='https://www.pickwin.mx/landing/androidapp/'
            >
              <img
                onClick={() => {
                  handleClick = handleClick ? handleClick() : null
                }}
                src={botonAndroid}
                className='img-responsive'
              />
            </a>
          </div>
        </div>
      </div>
    </CSSTransitionGroup>
  )
}

const initialState = {
  fbData: null,
  fbFailed: null,
  fbRegistration: null,
  formReferral: '',
  mounted: false,
  registerSuccess: null,
  userData: null,
  userId: null
}

const initial = { value: '', errorMessage: null, status: 'warning', isValid: false }

function RegistroFormPanel (props) {
  const {
    fingerPrint,
    signupEmail,
    user,
    isAuthenticated,
    submitting,
    responseFacebook,
    fbData,
    loginStatus
  } = useAuthContext()
  const [state, setState] = useMergedState(initialState)
  const [nick, setNick] = useState(initial)
  const [password, setPassword] = useState(initial)
  const [cpassword, setCPassword] = useState(initial)
  const [email, setEmail] = useState(initial)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [checkAge, setCheckAge] = useState(false)
  const [checkGetNews, setCheckGetNews] = useState(true)

  useEffect(() => {
    // TODO: We will get user data from context
    const userData = utils.getUserInfo()
    API.fetchSportsIndex()
      .then((response) => {
        if (props.referal) {
          utils.setReferer(props.referal)
        }
        setState({
          mounted: true,
          userData,
          userId: !userData ? null : userData.id,
          sports: response.data,
          formReferral: utils.getReferer()
        })
      })
      .catch((error) => {
        Sentry.captureException(error, { extra: 'Error FetchingSports' })
      })
  }, [])

  useEffect(() => {
    if (props.referal && (state.formReferral !== props.referal)) {
      setState({
        formReferral: props.referal,
      });
    }
  }, [props.referal])

  useEffect(() => {
    if (user && isAuthenticated) {
      if (props.type === 'popup') {
        setState(
          {
            submitting: false
          },
          () => {
            if (!props.updateUserInfo) {
              window.location.assign('/')
            } else {
              props.updateUserInfo({ user })
              utils.clearUserMetadata()
            }
            props.hideRegister()
          }
        )
      } else {
        setState(
          {
            redirectToLobby: true,
            registerSuccess: true,
            submitting: false
          },
          () => {
            window.location.assign('/')
          }
        )
      }
    }
  }, [user, isAuthenticated])

  //  This Function Sends the Register to the server
  const submit = () => {
    let userMetadata = utils.getUserMetadata()
    userMetadata = _.map(userMetadata, function (val, key) {
      return { name: key, value: val }
    })
    // push currentUrl
    userMetadata.push({ name: 'currentUrl', value: window.location.href })

    const data = {
      fingerprint: fingerPrint,
      referalCode: state.formReferral,
      nick: nick.value,
      email: email.value,
      password: password.value,
      cpassword: cpassword.value,
      tos: acceptTerms ? '1' : false,
      oldenough: checkAge ? '1' : false,
      newsletter: checkGetNews ? '1' : false,
      users_metadata: userMetadata,
      source: 'web',
    }

    signupEmail(data, (response) => {
      if (response.data.errors && !response.data.success) {
        const errors = response.data.errors
        // ['email', 'nick']
        const errorsKeys = Object.keys(errors)
        errorsKeys.forEach((err) => {
          const errorTypeNick = Object.keys(errors[err])
          const errorMessage = errors[err][errorTypeNick[0]]
          switch (err) {
            case 'nick':
              setNick(prevState => ({
                ...prevState,
                errorMessage
              }))
            break
          case 'email':
            setEmail(prevState => ({
              ...prevState,
              errorMessage
            }))
            break
          }
        })
      }
    })
  }

  const onFacebookResponse = (fbResponse) => {
    responseFacebook(fbResponse, (data) => {
      if (props.type === 'popup') {
        gaUtils.trackUserId(data.user.id)
        setState({ submitting: false })
        if (!props.updateUserInfo) {
          setTimeout(() => {
            window.location.assign('/')
          }, 1000)
        } else {
          props.updateUserInfo(data)
          props.hideRegister()
        }
      } else {
        utils.setUserInfo(data)
        setState({
          redirectToLobby: true,
          registerSuccess: true
        })
      }
    })
  }

  const setReferral = (e) => {
    setState({ formReferral: e.target.value } )
  }

  const setValue = (key, event) => {
    const { value } = event.target
    const regexMail = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

    if (key === 'nick') {
      if (value === '') {
        return setNick({ status: 'warning', value })
      }
      if (value.length < 4 || value.length > 12) {
        return setNick({
          value,
          status: 'error',
          errorMessage: 'Tu Nick debe de contener de 4 a 12 caracteres'
        })
      }
      return setNick({ status: 'success', value })
    }

    if (key === 'email') {
      if (value === '') {
        return setEmail({ status: 'warning', value })
      }
      if (value.length > 10) {
        if (regexMail.test(value)) {
          setEmail({ status: 'success', value })
        } else {
          setEmail({ status: 'error', value, errorMessage: 'Email Invalido' })
        }
      } else {
        setEmail({ status: 'error', value })
      }
    }

    if (key === 'password') {
      if (value === '') {
        return setPassword({ status: 'warning', value })
      }
      if (value.length >= 6) {
        setPassword({ status: 'success', value })
      } else {
        setPassword({ status: 'error', value })
      }
    }

    if (key === 'cpassword') {
      if (value === '') {
        return setCPassword({ status: 'warning', value })
      }
      if (value.length >= 6 && password.value === value) {
        setCPassword({ status: 'success', value })
      } else if (password.value.length && value.length) {
        setCPassword({ status: 'error', value, errorMessage: 'Tus contraseñas no son iguales' })
      } else {
        setCPassword({ status: 'error', value })
      }
    }
  }

  const isValidForm = (
    nick.status === 'success' &&
    email.status === 'success' &&
    password.status === 'success' &&
    cpassword.status === 'success' &&
    // Checkboxes
    acceptTerms && checkAge
  )

  if (!state.mounted || submitting) {
    return <Loading label='...' />
  }

  if (loginStatus === 'COMPLETE_FACEBOOK_DATA') {
    return (
      <RegistroFB
        type={props.type}
        fingerPrint={fingerPrint}
        hideRegister={props.hideRegister}
        formReferral={state.formReferral}
        updateUserInfo={props.updateUserInfo}
        fbData={fbData}
      />
    )
  }

  return (
    <div className='flex-row'>
      <div className='flex-col'>
        <div className='register-user-form'>
          <div id='panelTitle' className='flex-row row-no-padding '>
            <div className='flex-row row-center'>
              <span className='flex-col'> Registro </span>
              {props.type && props.type === 'popup' && (
                <i className='ion-ios-close-empty generic-button-close' onClick={props.hideRegister} />
              )}
            </div>
          </div>
          <div className='flex-container justify align'>
            <img src={pickwinLogo} style={{ width: '140px', height: 'auto' }} />
          </div>
          <DescargarAppBanner
            label='Da clic aquí para tener la mejor experiencia'
            path='https://pickwin.mx/landing/descarga-android'
            deviceType={detectDevice()}
          />
          <div className='titleForm'>
            <h5 style={{ margin: '0px' }}>Llena el formulario para poder jugar con otros pickwineros.</h5>
            <h5 style={{ color: '#F9CC30', margin: '0px', marginTop: '5px' }}>
              Asegurate que tu nombre y apellidos sean correctos e iguales a los de tu
              identificación, <strong>te los pediremos al momento de hacer un retiro.</strong>
            </h5>
          </div>
          <div className='form-container'>
            <div className='flex-row'>
              <FormGroup
                id='formNick'
                className='col-md-6'
                validationState={nick.status}
                style={{ marginBottom: '0px' }}
              >
                <ControlLabel className='label-input'>
                  Apodo
                </ControlLabel>
                <FormControl type='text' minLength='4' maxLength='12' onChange={(e) => setValue('nick', e)} className='register-input' />
                <FormControl.Feedback />
                {nick.errorMessage
                  ? (
                    <div className='login-failed' style={{ width: '100%' }}>
                      {nick.errorMessage}
                    </div>
                    )
                  : null}
                <HelpBlock>Este sera tu nombre con el que se te indentificara en pickwin</HelpBlock>
              </FormGroup>
              <FormGroup
                id='formMail'
                className='col-md-6'
                validationState={email.status}
                style={{ marginBottom: '0px' }}
              >
                <ControlLabel className='label-input'>Correo</ControlLabel>
                <FormControl type='text' onChange={(e) => setValue('email', e)} className='register-input' />
                <FormControl.Feedback />
                {email.errorMessage && (
                  <div className='login-failed' style={{ width: '100%' }}>
                    {email.errorMessage }
                  </div>
                )}
              </FormGroup>
            </div>

            <div className='flex-row'>
              <FormGroup
                id='formPswd'
                className='col-md-6'
                validationState={password.status}
                style={{ marginBottom: '0px' }}
              >
                <ControlLabel className='label-input'>Contraseña</ControlLabel>
                <FormControl type='password' onChange={(e) => setValue('password', e)} className='register-input' />
                <FormControl.Feedback />
                <HelpBlock>Tu contraseña debe de tener al menos 6 carácteres.</HelpBlock>
              </FormGroup>
              <FormGroup
                id='formPswdConfirm'
                className='col-md-6'
                validationState={cpassword.status}
                style={{ marginBottom: '0px' }}
              >
                <ControlLabel className='label-input'>Confirmar Contraseña</ControlLabel>
                <FormControl type='password' onChange={(e) => setValue('cpassword', e)} className='register-input' />
                <FormControl.Feedback />
                <HelpBlock>Confirma tu contraseña</HelpBlock>
                {cpassword.errorMessage && (
                  <span className='login-failed'>{cpassword.errorMessage}</span>
                )}
              </FormGroup>
            </div>

            <div className='flex-row'>
              <FormGroup id='formReferall' className='col-xs-6 col-xs-offset-3' style={{ marginTop: '1rem' }}>
                <ControlLabel className='label-input'>
                  ¿Quién te invitó?
                  <small
                    style={{ color: '#e00034', marginLeft: '10px', textTransform: 'capitalize' }}
                  >
                    [Opcional]
                  </small>
                </ControlLabel>
                <FormControl
                  type='text'
                  value={state.formReferral}
                  onChange={setReferral}
                  className='register-input'
                />
                <FormControl.Feedback />
                <HelpBlock>
                  <div className='label-input' style={{ color: '#fff' }}>Escribe el nick o el código amigo de quién te invito.</div>
                </HelpBlock>
              </FormGroup>
            </div>
            <div className='list-checkbox col-xs-12'>
              <label className='row' id='tos'>
                <div className='col-xs-1'>
                  <input
                    type='checkbox'
                    name='tos'
                    id='tos'
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                  <div className='checkbox-custom ' />
                </div>
                <div className='col-xs-11'>
                  <span>Acepto términos y Condiciones</span>
                </div>
              </label>
              <label className='row' id='oldenough'>
                <div className='col-xs-1'>
                  <input
                    type='checkbox'
                    name='oldenough'
                    id='oldenough'
                    checked={checkAge}
                    onChange={(e) => setCheckAge(e.target.checked)}
                  />
                  <div className='checkbox-custom ' />
                </div>
                <div className='col-xs-11'>
                  <span>Certifico que tengo al menos 18 años de edad</span>
                </div>
              </label>
              <label className='row' id='newsletter'>
                <div className='col-xs-1'>
                  <input
                    type='checkbox'
                    name='newsletter'
                    id='newsletter'
                    checked={checkGetNews}
                    onChange={(e) => setCheckGetNews(e.target.checked)}
                  />
                  <div className='checkbox-custom ' />
                </div>
                <div className='col-xs-11'>
                  <span>
                    Acepto que Pickwin me envíe avisos y comunicados por correo electrónico.
                  </span>
                </div>
              </label>
            </div>
            <div className='buttons-register'>
              <div className='col-xs-8 col-xs-offset-2' style={{ marginBottom: '20px' }}>
                <Button
                  className='btn-pick font-chivo'
                  onClick={() => {
                    submit()
                  }}
                  disabled={submitting === true || !isValidForm}
                >
                  <span>Completa tu Registro</span>
                </Button>
                <FacebookLogin
                  appId='973176056074364'
                  textButton='Regístrate con Facebook'
                  icon='fa-facebook'
                  autoLoad={false}
                  fields='id,name,first_name,last_name,gender,installed,verified,email,picture.type(large)'
                  scope='email,public_profile '
                  cssClass='my-facebook-button-class'
                  disableMobileRedirect
                  callback={onFacebookResponse}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistroFormPanel

